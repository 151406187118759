import React, { useCallback, useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import SVG from "react-inlinesvg";
import dayjs from "dayjs";
import { toAbsoluteUrl } from "_metronic/_helpers";
import { TextField } from "app/modules/Shared/components";
import { Table } from "app/modules/Shared/components"
import { useHistory } from "react-router-dom";
import { useGraphQL } from "../../Shared/hooks";
import * as queries from "graphql/queries";
import { USER_GENRE_MAP } from "../../Shared/constants/userGenreMap";
import { createPatientFilter } from "../../Shared/helpers/createSearchFilter";
import debounce from "lodash/debounce";

export default function UserListPage() {
  const history = useHistory();
  const columns = [
    {
      dataField: "name",
      text: "Nombre",
    },
    {
      dataField: "lastName",
      text: "Apellidos",
    },
    {
      dataField: "birthDate",
      text: "Edad",
      formatter: (cell, { birthDate }) => {
        return <span>{dayjs().diff(birthDate, "year") + " años"}</span>;
      }
    },
    {
      dataField: "phone",
      text: "Teléfono",
    },
    {
      dataField: "genre",
      text: "Sexo",
      formatter: (cell, { genre }) => {
        return <span>{USER_GENRE_MAP[genre]}</span>;
      }
    },
    {
      dataField: "createdAt",
      text: "Fecha de registro",
      formatter: (cell, { createdAt }) => {
        return <span>{dayjs(createdAt).format("DD/MM/YYYY hh:mm")}</span>;
      }
    },
    {
      dataField: "action",
      text: "",
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
      formatter: (cell, row, rowIndex) => {
        return (
          <>
            <button
              className="btn btn-link btn-sm"
              onClick={() => history.push("/pacientes/expediente/" + row.id)}
            >
              Ver expediente
            </button>
          </>
        );
      }
    },
  ];

  const { loading, runGraphQLOperation } = useGraphQL();
  const [patientList, setPatientList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const debouncedChangeHandler = useCallback(debounce(searchPatients, 500), [runGraphQLOperation]);

  useEffect(() => {
    getPatientList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPatientList = async () => {
    const { attributes: { sub: userId } } = await Auth.currentAuthenticatedUser();
    const response = await runGraphQLOperation({
      operation: queries.patientsByDate,
      variables: {
        createdByUserId: userId,
        sortDirection: "DESC",
        limit: 100
      }
    });
    setPatientList(response.patientsByDate.items);
  }

  const handleSearch = (event) => {
    event && event.persist();
    const term = event?.target?.value || "";
    setSearchTerm(term)
    debouncedChangeHandler(term);
  }

  function searchPatients(term) {
    if (term) {
      const filter = createPatientFilter(term, ["name", "lastName"]);
      runGraphQLOperation({
        operation: queries.listPatients,
        variables: {
          filter,
          limit: 1000
        }
      }).then(({ listPatients }) => {
        setPatientList(listPatients.items);
      });
    }
    else {
      getPatientList();
    }
  }

  const goToNewUser = () => {
    history.push("/pacientes/registro")
  };

  return (
    <div className="card card-custom">
      <div className="card-header">
        <div className="card-title">
          <h3 className="card-label">Listado de Pacientes</h3>
        </div>

        <div className="card-toolbar">
          <button className="btn btn-primary" onClick={goToNewUser}>
            <span className="svg-icon svg-icon-md svg-icon-white">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Add-user.svg")} />
            </span>
            Registrar paciente
          </button>
        </div>
      </div>

      <div className="card-body container-sm">
        <div className="row align-items-center">
          <div className="col-sm-12 col-md-6">
            <TextField
              name="term"
              label="Búsqueda"
              placeholder="Nombre o apellidos del paciente"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
          
          <div className="col-md-auto">
            <button className="btn btn-danger" onClick={handleSearch} disabled={!searchTerm}>
              Limpiar
            </button>
          </div>

          <div className="col-sm-12 mt-4">
            <Table
              columns={columns}
              data={patientList}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}