/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import ProfilePhoto from "../../../../../app/assets/img/profile.png"

export function UserProfileDropdown() {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light:
        objectPath.get(uiService.config, "extras.user.dropdown.style") ===
        "light",
    };
  }, [uiService]);

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div
          className={
            "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
          }
        >
          <span className="font-weight-bold font-size-base d-none d-md-inline mr-3">
            Mi cuenta
          </span>{" "}
          <span className="symbol symbol-35 symbol-light-primary">
            <img alt="Foto de perfil" className="hidden" src={ProfilePhoto} />
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-lg overflow-hidden">
        <>
          {!layoutProps.light && (
            <div
              className="d-flex align-items-center justify-content-between flex-wrap p-8 bg-primary-gradient"
            >
              <div className="symbol symbol-45 symbol-light-primary mr-3">
                <img alt="Foto de perfil" className="hidden" src={ProfilePhoto} />
              </div>
              <div className="text-white m-0 flex-grow-1 mr-3 font-size-h6">
                {/* TODO: Implementar api call para obtener nombre completo del médico */}
                Dr. Enrique López Montiel
              </div>
            </div>
          )}
        </>

        <div className="navi navi-spacer-x-0 py-5">
          {/* <Link to="/user-profile" className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <span className="navi-icon svg-icon mr-2">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Address-card.svg"
                  )}
                />
              </span>
              <div>
                <div className="font-weight-bold cursor-pointer">
                  Ver Perfil
                </div>                
              </div>
            </div>
          </Link> */}

          <Link to="/logout" className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <span className="navi-icon svg-icon mr-2">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Navigation/Sign-out.svg"
                  )}
                />
              </span>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">
                  Salir
                </div>                
              </div>
            </div>
          </Link>

          {/* <div className="navi-separator mt-3"></div> */}

          {/* <div className="navi-footer  px-8 py-5">
            <Link
              to="/logout"
              className="btn btn-light-primary font-weight-bold"
            >
              Sign Out
            </Link>
          </div> */}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
