import React, { useCallback, useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import { toAbsoluteUrl } from "_metronic/_helpers";
import { TextField } from "app/modules/Shared/components";
import { Table } from "app/modules/Shared/components";
import { Auth } from "aws-amplify";
import { useGraphQL } from "../../Shared/hooks";
import * as queries from "graphql/queries";
import debounce from "lodash/debounce";
import { createConsultationFilter } from "../../Shared/helpers/createSearchFilter";

export const ConsultationListPage = () => {
  const history = useHistory();
  const columns = [
    {
      dataField: "subject",
      text: "Asunto",
    },
    {
      dataField: "diagnosis",
      text: "Diagnóstico",
    },
    {
      dataField: "patient",
      text: "Paciente",
      formatter: (cell, { patient }) => {
        return <span>{`${patient.name} ${patient.lastName}`}</span>;
      },
    },
    {
      dataField: "createdAt",
      text: "Fecha de Registro",
      formatter: (cell, { createdAt }) => {
        return <span>{dayjs(createdAt).format("DD/MM/YYYY hh:mm")}</span>;
      },
    },
    {
      dataField: "action",
      text: "",
      classes: "text-center pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
      formatter: (cell, row, rowIndex) => {
        return (
          <>
            <Button variant="link" size="sm" onClick={() => history.push("/consultas/detalle/" + row.id)}>
              Ver más
            </Button>
          </>
        );
      },
    },
  ];

  const [searchTerm, setSearchTerm] = useState("");
  const { loading, runGraphQLOperation } = useGraphQL();
  const [consultationList, setConsultationList] = useState([]);

  const debouncedSearchPatients = useCallback(debounce(searchConsultations, 500), []);

  useEffect(() => {
    getConsultationList();
  }, []);

  const getConsultationList = async () => {
    const {
      attributes: { sub: userId },
    } = await Auth.currentAuthenticatedUser();
    const response = await runGraphQLOperation({
      operation: queries.listConsultationsByDate,
      variables: {
        userID: userId,
        sortDirection: "DESC",
      },
    });
    setConsultationList(response.listConsultationsByDate.items);
  };

  const handleSearch = event => {
    event && event.persist();
    const term = event?.target?.value || "";
    setSearchTerm(term);
    debouncedSearchPatients(term);
  };

  function searchConsultations(term) {
    if (term) {
      const filter = createConsultationFilter(term, ["subject", "diagnosis"]);
      runGraphQLOperation({
        operation: queries.listConsultations,
        variables: {
          filter,
          limit: 3000,
        },
      }).then(({ listConsultations }) => {
        setConsultationList(listConsultations.items);
      });
    } else {
      getConsultationList();
    }
  }

  const goToForm = () => {
    history.push("/consultas/registro");
  };

  return (
    <div className="card card-custom">
      <div className="card-header">
        <div className="card-title">
          <h3 className="card-label">Listado de Consultas</h3>
        </div>

        <div className="card-toolbar">
          <button className="btn btn-primary" onClick={goToForm}>
            <span className="svg-icon svg-icon-md svg-icon-white">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File-plus.svg")} />
            </span>
            Registrar consulta
          </button>
        </div>
      </div>

      <div className="card-body container-sm">
        <div className="row align-items-center">
          <div className="col-sm-12 col-md-6">
            <TextField
              name="term"
              label="Búsqueda"
              placeholder="Asunto o diagnóstico de la consulta"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>

          <div className="col-md-auto">
            <button className="btn btn-danger" onClick={handleSearch} disabled={!searchTerm}>
              Limpiar
            </button>
          </div>

          <div className="col-sm-12">
            <Table columns={columns} data={consultationList} loading={loading} />
          </div>
        </div>
      </div>
    </div>
  );
};
