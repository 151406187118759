import React, { useEffect, useState } from "react";
import { Col, Row, Tab, Tabs, Button } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { Table } from "app/modules/Shared/components";
import TextLabel from "../../Shared/components/TextLabel";
import * as queries from "graphql/queries";
import { useGraphQL } from "../../Shared/hooks";
import { USER_GENRE_MAP } from "../../Shared/constants/userGenreMap";
import { IconSVG } from "../../Shared/components/IconSVG";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export const UserDetailPage = () => {
  const { id } = useParams();
  const history = useHistory();
  const { loading, runGraphQLOperation } = useGraphQL();
  const [patient, setPatient] = useState(null);
  const columns = [
    {
      dataField: "subject",
      text: "Asunto",
    },
    {
      dataField: "diagnosis",
      text: "Diagnóstico",
    },
    {
      dataField: "createdAt",
      text: "Fecha",
      formatter: (cell, { createdAt }) => {
        return <span>{dayjs(createdAt).format("DD/MM/YYYY hh:mm")}</span>;
      },
    },
    {
      dataField: "action",
      text: "",
      formatter: (cell, row, rowIndex) => {
        return (
          <>
            <button className="btn btn-link btn-sm" onClick={() => history.push("/consultas/detalle/" + row.id)}>
              Ver más
            </button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    getUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserDetails = async () => {
    const response = await runGraphQLOperation({
      operation: queries.getPatient,
      variables: { id },
    });
    setPatient(response.getPatient);
  };

  const goBack = () => {
    history.goBack();
  };

  const goToForm = () => {
    history.push("/consultas/registro/" + id);
  };

  return (
    <div className="card card-custom">
      
      <div className="card-header">
        <div className="card-title">
          <Button variant="link" className="px-0 mr-4" onClick={goBack}>
            <IconSVG absolutePath="/media/svg/icons/Navigation/Close.svg" />
          </Button>
          <h3 className="card-label">
            Expediente de {patient ? `${patient?.name} ${patient?.lastName}`.toUpperCase() : "PACIENTE"}
          </h3>
        </div>
        <div className="card-toolbar">
          <button className="btn btn-danger" onClick={goToForm}>
            <span className="svg-icon svg-icon-md svg-icon-white">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File-plus.svg")} />
            </span>
            Nueva consulta
          </button>
        </div>
      </div>

      <div className="card-body container-sm">
        <Tabs defaultActiveKey="home" className="mb-3">
          <Tab eventKey="home" title="Información General" key="home">
            <Row className="p-6">
              <Col sm={12}>
                <h4 className="mb-8">Datos personales del paciente</h4>
              </Col>
              <Col sm={6}>
                <TextLabel label="Nombre" text={patient?.name} loading={loading} />
              </Col>
              <Col sm={6}>
                <TextLabel label="Apellidos" text={patient?.lastName} loading={loading} />
              </Col>
              <Col sm={6}>
                <TextLabel
                  label="Fecha de nacimiento"
                  text={dayjs(patient?.birthDate).format("DD/MM/YYYY")}
                  loading={loading}
                />
              </Col>
              <Col sm={6}>
                <TextLabel label="Género" text={USER_GENRE_MAP[patient?.genre]} loading={loading} />
              </Col>
              <Col sm={6}>
                <TextLabel label="Telefóno" text={patient?.phone} loading={loading} />
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="profile" key="profile" title="Historial Clínico">
            <Row className="p-6">
              <Col sm={12}>
                <h4 className="mb-8">Lista de consultas</h4>
              </Col>
              <Col sm={12}>
                <Table columns={columns} data={patient?.consultations?.items} />
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </div>

    </div>
  );
};
